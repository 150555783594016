import React from "react"
import Layout from "../components/layout"
import Image from "../components/image"
import Image2 from "../components/image2"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
      <div class="row">
        <div class="col">
          <div class="p-1 mt-5">
            <p><strong><em>History</em>:</strong> OOB was formed (under our former name Order of Bahamut) just a couple weeks after the server was opened in October 2005. &lt;OOB&gt; was made in November 2006 and has been raiding on Kael'thas since level 60 Molten Core. We stopped Mythic raiding in BFA (2018).</p>
            <p class="p1"><strong><em>Efficiency</em>:</strong> We have reached high levels of progression on a limited raiding schedule (9 hours/week). We strive to be as efficient with our members' time as we can.</p>
            <p class="p1"><strong><em>Community</em>:</strong> Our raiding environment is relaxed and friendly. We encourage our raiders to invite their friends and family to the guild. Hundreds of people have contributed to OOB since our founding in 2006 and all are welcome to stay whether they're raiding or not.</p>
          </div>
          <div class="text-center">
            <figure class="figure p-1">
              <Image />
              <figcaption class="figure-caption">Our first major kill was Ragnaros in 2006.</figcaption>
            </figure>
            
            <figure class="figure p-1">
              <Image2 />
              <figcaption class="figure-caption">Heroic Ragnaros in 2011.</figcaption>
            </figure>
          </div>

          <p>&nbsp;</p>
          <h3 class="p1"><br />Guild Code of Conduct:</h3>
          <ol class="ul1">
            <li class="li1">Support the guild and your guild mates. A guild is like a family, and as such, you should treat each member with the same respect you wish to receive yourself.</li>
            <li class="li1">Teasing and light-hearted jokes are normal, but if a fellow guild mate asks you to stop, do so. On the flip side, develop thicker skin. Teasing is a part of life, and while the officers will keep it from going overboard, not every joke is reason to take offence.</li>
            <li class="li1">Insults and blatantly offensive comments will not be tolerated, whether or not you think the person deserves it.</li>
            <li class="li1">Do not complain about someone or their play style. Suggest how they can improve. Failure on their part to heed such suggestions is not grounds for you to then hassle them about it. If it is something that impedes the guild's progress, talk to an officer, if not, let them play the game.</li>
            <li class="li1">Take care of disagreements in a civilized fashion. Disagreements are a part of life, but arguments and fights should not be. If you have a problem with a fellow guildie, approach them about it, and attempt to work it out. If an agreement can not be met, or you simply don't feel comfortable bringing it up with the other person, talk to an officer. They're here to help.</li>
            <li class="li1">Make an effort to keep your language polite in guild chat and discord. Excessive crude comments are grounds for demotion and/or removal from a raid or the guild.</li>
            <li class="li1">Registration on the guild discord is required for all members. Information will be posted there that can not be properly conveyed in-game. It is your job to keep up with what is going on in the guild.</li>
            <li class="li1">Conduct yourself outside the guild in a way that will reflect well on our guild. Do not ninja loot or kills. Do not be rude and offensive to anyone (even if they're horde, that's what /chicken is for), and do not spam any in-game channels.</li>
            <li class="li1">Make sure you want to be here. No one is forcing you to be in this guild.</li>
          </ol>
          <h3 class="p1">&nbsp;</h3>
          <h3 class="p1">Raiding Rules:</h3>
          <ul class="ul1">
            <li class="li1">Check the &ldquo;Raid Schedule&rdquo; page for the most up to date raiding schedule. Invites go out 30 minutes before the official start of the raid, and you are considered <strong>absent</strong> if you are not online, ready to accept the invite at this time.</li>
            <li class="li1">You must be at the raid instance before the raid officially starts with full durability, and all potions, elixirs, flasks, reagents, buffing food, bandages, and any special gear, outside your normal set that may be needed.</li>
            <li class="li1">In order to raid you must have downloaded, installed, and configured Discord and Deadly Boss Mods.</li>
            <li class="li1">You must be raid specced for all raids. Remember that we are a team, and as such if you are asked to fill a role, that is where we need you.</li>
            <li class="li1">You must pay attention to the raid leader at all times. Save questions and clarification until after he/she has finished giving instructions.</li>
            <li class="li1">Before leaving the raid or going AFK, you must let one of the officers know. We rely on you being there to do your job. If you are not, it hinders the progression of the entire raid.</li>
            <li class="li1">Damage meters are a perfectly acceptable mod to have to gauge yourself against previous weeks and others, however, reporting data to the raid or guild chat during the raid is not tolerated.</li>
          </ul>
        </div>
      </div>
  </Layout>
)

export default IndexPage
